<template>
  <div class="card-box">
    <van-image
      round
      width="40px"
      height="40px"
      fit="cover"
      class="avatar"
      :src="dataList.client_card_cfg.avatar"
      @click="show = true"
    />
    <div class="info" @click="show = true">
      <div class="realname">
        <span class="txt1">{{ dataList.realname }}</span>
        <span class="txt2 font12 gray">{{
          job_title[dataList.client_card_cfg.job_title] || '服务顾问'
        }}</span>
      </div>
      <a class="company gray font12">
        {{ dataList.company_card_cfg.name }}
      </a>
    </div>
    <div class="tel">
      <van-icon name="phone-circle-o" @click="callPhone(dataList.mobile)" />
    </div>
    <div class="qr">
      <van-icon name="qr" @click="show = true" />
    </div>
    <van-popup v-model="show">
      <div class="card-popup">
        <div class="top">
          <van-image
            round
            width="70px"
            height="70px"
            fit="cover"
            class="avatar"
            :src="dataList.client_card_cfg.avatar"
          />

          <div class="info">
            <div class="realname">
              {{ dataList.realname }}
              <span class="txt">{{
                job_title[dataList.client_card_cfg.job_title] || '服务顾问'
              }}</span>
            </div>
            <div class="line">
              <a :href="'tel:' + dataList.mobile"
                ><van-icon class="icon" name="phone" /> {{ dataList.mobile }}</a
              >
            </div>
            <div class="line">
              <van-icon class="icon" name="wechat" />
              {{ dataList.client_card_cfg.wechat }}
            </div>
            <div class="line">
              <van-icon class="icon" name="shop" />
              {{ dataList.company_card_cfg.name }}
            </div>
          </div>
        </div>

        <!-- <van-divider
        :style="{ padding: '0 16px', width: '100%', fontSize: '12px' }"
      ></van-divider> -->
        <div class="bottom">
          <van-image
            width="150px"
            height="150px"
            fit="cover"
            class="qrcode"
            :src="dataList.card_qrcode"
          />
          <div class="desc">长按识别小程序码进入名片</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      dataList: {
        client_card_cfg: {
          avatar: '',
          name: ''
        },
        company_card_cfg: {
          name: ''
        }
      },
      job_title: {}
    }
  },
  mounted () {
    this.$axios.post('/wxc/index/bd_cfg').then(res => {
      this.job_title = res.data.job_title
    })

    this.$axios.post('/wxc/index/card_page', {
      seller_id: Number(this.$route.query.from_id) || 0
    }).then(res => {
      this.dataList = res.data
    })
  },
  methods: {
    callPhone (phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }
  }
}
</script>
<style lang="scss" scoped>
.card-box {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  background: #fff;
  .info {
    padding: 0 8px;
    flex: 1;
    .realname {
      margin-bottom: 3px;
      .txt1 {
        font-weight: bolder;
        font-size: 16px;
        margin-right: 3px;
        color: #333;
      }
    }
  }
  .tel,
  .qr {
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-left: 10px;
    color: #0c2481;
  }
  .tel {
    color: #0c2481;
  }
}

::v-deep .van-popup--center{
  width: 80%;
  border-radius: 10px;
}

.card-popup {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    background-color: rgba(0, 0, 0, 0.06);
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bottom {
    padding: 20px 0;
  }

  .avatar {
    border: 4px #fff solid;
  }

  .info {
    padding: 10px 0;
    .realname {
      font-size: 21px;
      text-align: center;
      margin-bottom: 20px;
      .txt {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 0;
      a {
        color: #333;
      }
      .icon {
        margin-right: 3px;
      }
    }
  }
  .desc {
    padding-top: 20px;
    font-size: 12px;
    opacity: 0.5;
  }
}
</style>